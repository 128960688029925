var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("meta-actions-header", {
        staticClass: "mb-4",
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "breadcrumb",
                        {
                          staticClass: "flex flex-1 text-gray-700",
                          class: { "text-orange-500": !_vm.campaignId },
                        },
                        [
                          _vm.campaignId
                            ? _c("span", [
                                _vm._v(": "),
                                _vm.campaign
                                  ? _c(
                                      "span",
                                      { staticClass: "text-orange-500" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.campaign.name) + " "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        {
          staticClass:
            "flex flex-col p-6 text-left bg-white rounded rounded-lg shadow-md lg:p-12",
        },
        [
          _vm.isLoading || !_vm.campaign
            ? _c("card-form-loading", {
                staticClass: "mt-10",
                attrs: { rows: 2 },
              })
            : _c(
                "sun-form",
                {
                  on: {
                    submit: _vm.submitForm,
                    keypress: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.submitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("card-form", {
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [_vm._v(" Basic Info")]
                        },
                        proxy: true,
                      },
                      {
                        key: "form",
                        fn: function () {
                          return [
                            _c("form-row", {
                              scopedSlots: _vm._u([
                                {
                                  key: "left",
                                  fn: function () {
                                    return [
                                      _c(
                                        "sun-label-group",
                                        { attrs: { text: "Name" } },
                                        [
                                          _c("asterix-input", {
                                            attrs: {
                                              id: "campaign-name",
                                              maxlength: 50,
                                              minlength: 5,
                                              name: "name",
                                              placeholder: "A name...",
                                              required: "required",
                                              type: "text",
                                              "validate-on-blur": "",
                                            },
                                            model: {
                                              value: _vm.campaign.name,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "campaign.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "right",
                                  fn: function () {
                                    return [
                                      _c(
                                        "sun-label-group",
                                        { attrs: { text: "Status" } },
                                        [
                                          _c("sun-select", {
                                            attrs: {
                                              options: _vm.statusOptions,
                                              "track-by": "value",
                                              label: "name",
                                              name: "status",
                                              "close-on-select": "",
                                              required: "required",
                                              "text-error":
                                                "The field is required.",
                                              "add-hex-color": "orange",
                                            },
                                            model: {
                                              value: _vm.campaign.status,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "status",
                                                  $$v
                                                )
                                              },
                                              expression: "campaign.status",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                            _c("form-row", {
                              scopedSlots: _vm._u([
                                {
                                  key: "left",
                                  fn: function () {
                                    return [
                                      _c(
                                        "sun-label-group",
                                        { attrs: { text: "Campaign ID" } },
                                        [
                                          _c("asterix-input", {
                                            attrs: {
                                              id: "campaign-id",
                                              name: "campaignId",
                                              disabled: !!_vm.campaign.id,
                                              maxlength: 6,
                                              minlength: 3,
                                              placeholder: "A campaign id...",
                                              required: "required",
                                              type: "number",
                                              "validate-on-blur": "",
                                            },
                                            model: {
                                              value: _vm.campaign.campaignId,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "campaignId",
                                                  $$v
                                                )
                                              },
                                              expression: "campaign.campaignId",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "right",
                                  fn: function () {
                                    return [
                                      _c(
                                        "sun-label-group",
                                        { attrs: { text: "Total Limit" } },
                                        [
                                          _c("asterix-input", {
                                            attrs: {
                                              type: "number",
                                              min: 1,
                                              placeholder: "1",
                                              name: "totalLimit",
                                              "auto-validate": "",
                                            },
                                            model: {
                                              value: _vm.campaign.totalLimit,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.campaign,
                                                  "totalLimit",
                                                  $$v
                                                )
                                              },
                                              expression: "campaign.totalLimit",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c(
                    "div",
                    { staticClass: "flex w-1/2 mt-12" },
                    [
                      _c("save-button", {
                        attrs: {
                          id: "campaign-submit",
                          loading: _vm.isLoading,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }