<template>
  <div>
    <meta-actions-header class="mb-4" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700" :class="{ 'text-orange-500': !campaignId }">
          <span v-if="campaignId"
            >:
            <span v-if="campaign" class="text-orange-500">
              {{ campaign.name }}
            </span>
          </span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <div class="flex flex-col p-6 text-left bg-white rounded rounded-lg shadow-md lg:p-12">
      <card-form-loading v-if="isLoading || !campaign" :rows="2" class="mt-10"></card-form-loading>

      <sun-form v-else @submit="submitForm" @keypress.enter="submitForm">
        <card-form>
          <template #title> Basic Info</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Name">
                  <asterix-input
                    id="campaign-name"
                    v-model="campaign.name"
                    :maxlength="50"
                    :minlength="5"
                    name="name"
                    placeholder="A name..."
                    required="required"
                    type="text"
                    validate-on-blur
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Status">
                  <sun-select
                    v-model="campaign.status"
                    :options="statusOptions"
                    track-by="value"
                    label="name"
                    name="status"
                    close-on-select
                    required="required"
                    text-error="The field is required."
                    add-hex-color="orange"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <form-row>
              <template #left>
                <sun-label-group text="Campaign ID">
                  <asterix-input
                    id="campaign-id"
                    v-model="campaign.campaignId"
                    name="campaignId"
                    :disabled="!!campaign.id"
                    :maxlength="6"
                    :minlength="3"
                    placeholder="A campaign id..."
                    required="required"
                    type="number"
                    validate-on-blur
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Total Limit">
                  <asterix-input
                    v-model="campaign.totalLimit"
                    type="number"
                    :min="1"
                    placeholder="1"
                    name="totalLimit"
                    auto-validate
                  />
                </sun-label-group>
              </template>
            </form-row>
          </template>
        </card-form>
        <div class="flex w-1/2 mt-12">
          <save-button id="campaign-submit" :loading="isLoading" />
        </div>
      </sun-form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { createCampaign, getCampaignById, updateCampaign } from '@/services/modules/ecommerce/campaign';
import { Toast } from '@/model/shared/Toast';
import Campaign from '@/entities/ecommerce/Campaign';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import metaInfo from '@/mixins/common/metaInfo';
import SaveButton from '@/components/atoms/SaveButton';
import { list as listCampaignRoute } from '@/router/private/modules/ecommerce/demand/campaign/list';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import AsterixInput from '@/components/atoms/AsterixInput';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import { CAMPAIGN_STATUS } from '@/model/modules/ecommerce/campaign/CampaignStatus';
import { IS_LOADING, SET_LOAD } from '@/store/modules/load/keys';
import apiRequest from '@/utils/apiRequest';

export default {
  name: 'CampaignEdit',
  components: {
    Breadcrumb,
    FormRow,
    CardForm,
    SaveButton,
    MetaActionsHeader,
    CardFormLoading,
    AsterixInput,
  },
  mixins: [metaInfo],
  props: {
    campaignId: { type: String, default: () => null },
  },
  data: () => ({
    campaign: null,
    statusOptions: CAMPAIGN_STATUS,
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      isLoading: IS_LOADING,
    }),
  },
  async created() {
    this.setLoad();
    await apiRequest(async () => {
      if (this.campaignId) {
        await this.getCampaign();
      } else {
        this.campaign = new Campaign(this.activeClient);
      }
      this.setLoad(false);
    }).catch(error => {
      this.createToast(Toast.error('Error to load campaign', error.message));
      this.setLoad(false);
    });
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      setLoad: SET_LOAD,
    }),
    metaTitleReplacement() {
      return { campaign: this.campaign?.name };
    },
    async getCampaign() {
      const { data } = await getCampaignById(this.campaignId);
      this.campaign = data;
      this.updateTitle();
    },
    async submitForm({ valid }) {
      if (!valid) return;
      try {
        this.setLoad();
        if (this.campaignId) {
          await updateCampaign(this.campaign);
          this.createToast(
            Toast.success('Campaign updated', `Campaign ${this.campaign.name} was updated successfully.`)
          );
        } else {
          await createCampaign(this.campaign);
          this.createToast(
            Toast.success('Campaign created', `Campaign ${this.campaign.name} was created successfully.`)
          );
        }
        await this.$router.push(listCampaignRoute);
      } catch (error) {
        this.createToast(Toast.error('Campaign not saved', error.message));
      } finally {
        this.setLoad(false);
      }
    },
  },
};
</script>
